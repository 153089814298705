<template>
    <section class="overflow-auto scroll-none" style="height:calc(100vh - 148px)">
        <div class="row mx-0 px-4 bg-white a-center mb-4" style="height:56px;">
            <i class="icon-arrow-left f-20" @click="$router.back()" />
            <div class="col f-15 f-500">
                Detalle del cupón
            </div>
        </div>
        <div class="row mx-0 j-center">
            <div class="col-sm-12 col-11 px-0">
                <div class="card-cupon d-flex bg-white position-relative cr-pointer">
                    <div class="space-img pr-3 h-100 d-middle">
                        <img src="/img/ilustraciones/descount.svg" width="50" height="50" />
                    </div>
                    <div class="col px-2">
                        <div class="row mx-0 align-items-end text-purple pt-2">
                            <div class="col-auto px-0 f-600 f-30 text-cupon">
                                $ 10%
                            </div>
                            <small class="pl-2">
                                Descuento
                            </small>
                        </div>
                        <p class="text-detail mt-3">
                            Primera compra
                        </p>
                        <p class="text-fetch mt-1">
                            Expira: 10 Abr. 2022
                        </p>
                    </div>
                    <!-- Circulo cupo -->
                    <div :class="`circled-coupon`" />
                </div>
            </div>
            <div class="col-sm-12 col-11 px-0 mt-3">
                <div class="bg-white shadow-14 p-3 br-10">
                    <div class="row mx-0">
                        <div class="col px-2">
                            <p class="f-15">Expira: <span class="f-18 f-600 text-purple">HOY</span> </p>
                            <p class="f-15 text-gris2">A las 07:30 p.m. </p>
                        </div>
                    </div>
                    <div class="row mx-0 a-center mt-2">
                        <i class="icon-cash f-15" />
                        <div class="col px-3">
                            <p class="f-15">Compra mínima: <span class="f-18 f-600 text-purple">$100.000</span> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-11 px-0 mt-3">
                <div class="bg-white shadow-14 p-3 br-10">
                    <div class="row mx-0">
                        <i class="icon-coupon f-18" />
                        <div class="col px-2 f-15">
                            {{$config.proyecto}}: $15.000 para tu mercado con el cupon SOYLEECHE. Escogemos todo como tu lo harias. Min Compra $40.000. T&C.
                        </div>
                    </div>
                    <div class="row mx-0 mt-3">
                        <i class="icon-product f-18" />
                        <div class="col px-2 f-15">
                            {{$config.proyecto}}: $15.000 para tu mercado con el cupon SOYLEECHE. Escogemos todo como tu lo harias. Min Compra $40.000. T&C.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-11 px-0 my-3">
                <div class="bg-white br-10 shadow-14 p-2">
                    <div class="row mx-0 a-center">
                        <i class="icon-information-outline f-18" />
                        <div class="col px-2 f-12">
                            Aplica sobre el valor de los productos.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
.card-cupon{
    width: 100%;
    height: 108px;
    border-radius: 12px;
    padding-left: 35px;
    box-shadow: 0px 2px 4px #00000014;
    z-index: 2;
    .circled-coupon{
        width: 44px;
        position: absolute;
        left: -22px;
        top: 31px;
        height: 44px;
        background-color: #F6F9FB;
        z-index: 1;
        border-radius: 50%;
        box-shadow: 1px 0px 4px #00000014;
        &::after{
            content: '';
            position: absolute;
            left: -24px;
            top: -1px;
            z-index: 2;
            height: 44px;
            width: 44px;
            background-color: #F6F9FB
        }
        &-active{
            @extend .circled-coupon;
            border: 1px solid var(--color-general);
        }
    }
    &.border-active{
        border: 1px solid var(--color-general);
    }
    .space-img{
        border-right: dashed 3px #F6F6F6;
    }
    .text-cupon{
        line-height: 29px;
    }
    .text-detail{
        color: #b1b9c1;
    }
    .text-fetch{
        color: #D3D7DB;
    }
}
</style>