<template>
    <section class="px-xl-5 px-lg-3 px-md-3 px-sm-3 px-2 pt-4 overflow-auto custom-scroll" style="height:calc(100vh - 84px)">
        <cargando v-if="cargando" />
        <div v-if="showContent" class="row mx-0 j-center h-100 a-center">
            <div v-if="estado != 'afiliado'" class="col-auto mr-5">
                <div class="space-info p-3">
                    <img :src="convenio.logo" class="br-4 obj-cover border" width="132" height="132" />
                    <div class="row mx-0 py-4" />
                    <p class="text-center f-16 f-600 mb-3">
                        {{ convenio.nombre }}
                    </p>
                    <p class="f-14 mb-2">
                        {{ convenio.mensaje }}
                    </p>
                    <template v-if="showContacts">
                        <p class="f-14 mb-4">
                            Para mayor información comunícate al:
                        </p>
                        <div v-for="(data, index) in convenio.contactos" :key="index" class="row mx-0 pl-4 a-center mb-3">
                            <i class="icon-phone" />
                            <div class="col px-2">
                                <p class="f-12 f-600">{{ data.nombre }}</p>
                                <p class="f-12 f-400">{{ data.contacto_telefono }}</p>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <component :is="estado" :convenio="convenio" @enviarSolicitud="solicitudConvenio" @accion="acciones" />
        </div>
    </section>
</template>

<script>
import Service from '~/services/nomina/nomina'

export default {
    middleware:'menu-check',
    components: {
        enviar: () => import('./components/enviar'),
        enviado: () => import('./components/enviado'),
        afiliado: () => import('./components/afiliado')
    },

    data(){
        return {
            cargando: false,
            showContent: false,
            showContacts: false,
            convenio: {},
            estado: null
        }
    },

    computed:{
        idConvenio(){
            return Number(this.$route.params.idConvenio);
        },
    },

    watch: {
        idConvenio(){
            this.getConvenio();
        },
    },

    mounted(){
        this.getConvenio();
    },

    methods: {
        async getConvenio(){
            try {
                this.cargando = true;

                const {data} = await Service.getConvenio(this.idConvenio);
                this.convenio = data.convenio;
                this.showContacts = data.convenio.contactos.length > 0 ? true : false;
                this.showComponent(data.estadoUserConvenio);
            } catch(e){
                this.errorCatch(e)
            } finally {
                this.showContent = true;
                this.cargando = false;
            }
        },

        async showComponent(estado){
            switch(estado){
            case 0:
            case 4:
            case 5:
            case 6:
                this.estado = 'enviar';
                break;
            case 1:
                this.estado = 'enviado';
                break;
            case 2:
            case 3:
                this.estado = 'afiliado';
                break;
            }
        },

        async solicitudConvenio(model){
            try {
                model.idConvenio = this.idConvenio;
                const form = this.crearFormData(model)
                const { data } = await Service.postSolicitudConvenio(form)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.estado = 'enviado';
            } catch(e){
                this.errorCatch(e)
            }
        },

        async acciones(accion){
            if(accion === 'irEliminar'){
                return this.cancelarSoli();
            }
            if(accion === 'irTienda'){
                return this.goShoping();
            }
            if(accion === 'irDesvincular'){
                return this.desvincularDelConvenio();
            }
        },

        async cancelarSoli(){
            try {
                const { data } = await Service.deleteSolicitudConvenio(this.idConvenio)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.estado = 'enviar';
            } catch(e){
                this.errorCatch(e)
            }
        },

        async desvincularDelConvenio(){
            try {
                const { data } = await Service.desvincularClienteConvenio()
                if(data.exito){
                    this.notificacion('Mensaje', data.mensaje, data.tipo);
                    this.$router.replace({name: 'home'})
                }
            } catch(e){
                this.errorCatch(e)
            }
        },

        goShoping(){
            this.$router.push({name:'home'});
        },
    }
}
</script>
<style lang="scss" scoped>
.space-info{
    width: 312px;
    min-height: 337px;
    position: relative;
    border-radius: 11px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px #00000014;
    img{
        position: absolute;
        top: -84px;  
        left: 91px;
    }
}
.card-file-upload{
    height: 60px;
    display: flex;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px #00000014;
    .space-file{
        width: 48px;
        background-color: #DFE4E8;
        display: flex;
        color: #4778B1;
        align-items: center;
        justify-content: center;
        height: 100%;
        border-radius: 11px 0px 0px 11px;
    }
    .space-delete{
        width: 60px;
        background-color: #FF5158;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #FFFFFF;
        border-radius: 11px;
    }
}

</style>