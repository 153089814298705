<template>
    <section class="px-xl-5 px-lg-3 px-md-3 px-sm-3 px-2 pt-4 overflow-auto custom-scroll" style="height:calc(100vh - 84px)">
        <cargando v-if="cargando" />
        <div class="row mx-0 mb-4">
            <div class="col pl-0">
                <p class="f-28 text-general f-600">
                    Productos favoritos
                </p>
                <div class="w-100" />
                <div class="like-bar mb-2" />
            </div>
        </div>
        <template v-if="productos.length">
            <div class="row mx-0">
                <div v-for="(producto,key) in productos" :key="key">
                    <card-producto 
                    :producto="producto"
                    :promocion="producto.data_promocion"
                    class="mr-4 mb-4"
                    />
                </div>
            </div>
        </template>
        <template v-else>
            <div class="d-middle-center" style="width:100%;height:55vh;">
                <div class="col-auto">
                    <div class="row mx-0 j-center">
                        <img :src="funImagenGeneral(43)" width="180" height="180" />
                    </div>
                    <p class="text-center mt-3">
                        Presiona <i class="icon-heart text-red f-20" /> para añadir un <br /> producto a favoritos
                    </p>
                    <div class="row mx-0 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3 mt-2 mt-2 mb-md-2">
                        <div class="col-12 px-2">
                            <div class="btn-general" @click="$router.push({name:'home.tienda'})">
                                Ir a la Tienda
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import Favoritos from '~/services/favoritos/favoritos'
export default {
    data(){
        return {
            cargando: false,
            hasData: true,
            productos:[]
        }
    },
    mounted(){
        this.getFavoritos()
    },
    methods:{
        async getFavoritos(){
            try {
                this.cargando = true;
                const {data} = await Favoritos.getFavoritos()
                this.productos = data.productos



            } catch (error){
                this.errorCatch(error)
            } finally {
                this.cargando = false;
            }
        }
    }
}
</script>