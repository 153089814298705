<template>
    <section class="pt-4 overflow-auto scroll-none" style="height:calc(100vh - 148px)">
        <div class="row mx-0 j-center pb-3 mb-3">
            <div class="col-10">
                <div class="position-relative">
                    <el-input v-model="buscar" prefix-icon="icon-search f-18" placeholder="Buscar cupon" class="w-100 br-20 input-search-cupon" />
                    <!-- button -->
                    <button type="button" class="btn-buscar f-14 px-3">
                        Buscar
                    </button>
                </div>
            </div>
        </div>
        <div v-for="(data, idx) in 3" :key="idx" class="row mx-0 j-center mb-3">
            <div class="card-cupon d-flex bg-white position-relative cr-pointer" :class="cuponActivo == idx + 1 ? 'border-active' : ''" @click="cuponActivo = idx + 1;$router.push({name: 'cupon.detalle-cupon'})">
                <div class="space-img pr-3 h-100 d-middle">
                    <img src="/img/ilustraciones/descount.svg" width="50" height="50" />
                </div>
                <div class="col px-2">
                    <div class="row mx-0 align-items-end text-purple pt-2">
                        <div class="col-auto px-0 f-600 f-30 text-cupon">
                            $ 10%
                        </div>
                        <small class="pl-2">
                            Descuento
                        </small>
                    </div>
                    <p class="text-detail mt-3">
                        Primera compra
                    </p>
                    <p class="text-fetch mt-1">
                        Expira: 10 Abr. 2022
                    </p>
                </div>
                <!-- Circulo cupo -->
                <div :class="`circled-coupon${ cuponActivo == idx + 1 ? '-active' : '' }`" />
            </div>
        </div>
        <p class="text-center f-15 f-500 mt-5 mb-3">
            Cupones de recompensas
        </p>
        <div v-for="(data, idx) in 3" :key="`cupon-rec-${idx}`" class="row mx-0 j-center mb-3">
            <div class="card-cupon d-flex bg-white position-relative cr-pointer" :class="cuponActivo == idx + 1 ? 'border-active' : ''" @click="cuponActivo = idx + 1">
                <div class="space-img pr-3 h-100 d-middle">
                    <img src="/img/ilustraciones/descount.svg" width="50" height="50" />
                </div>
                <div class="col px-2">
                    <div class="row mx-0 align-items-end text-purple pt-2">
                        <div class="col-auto px-0 f-600 f-30 text-cupon">
                            $ 10%
                        </div>
                        <small class="pl-2">
                            Descuento
                        </small>
                    </div>
                    <p class="text-detail mt-3">
                        Primera compra
                    </p>
                    <p class="text-fetch mt-1">
                        Expira: 10 Abr. 2022
                    </p>
                </div>
                <!-- Circulo cupo -->
                <div :class="`circled-coupon${ cuponActivo == idx + 1 ? '-active' : '' }`" />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            buscar: '',
            cuponActivo: null
        }
    }
}
</script>
<style lang="scss" scoped>
.button-search{
    height: 44px;
    border-radius: 20px;
}
.btn-buscar{
    background-color: var(--color-general) !important;
    position: absolute;
    right: 4px;
    height: 36px;
    top: 3px;
    border: 0px;
    border-radius: 20px;
    color: #FFFFFF;
    height: 34px;
    &:focus{
        outline: none !important;
    }
}
.card-cupon{
    width: 346px;
    height: 108px;
    border-radius: 12px;
    padding-left: 35px;
    box-shadow: 0px 2px 4px #00000014;
    z-index: 2;
    .circled-coupon{
        width: 44px;
        position: absolute;
        left: -22px;
        top: 31px;
        height: 44px;
        background-color: #F6F9FB;
        z-index: 1;
        border-radius: 50%;
        box-shadow: 1px 0px 4px #00000014;
        &::after{
            content: '';
            position: absolute;
            left: -24px;
            top: -1px;
            z-index: 2;
            height: 44px;
            width: 44px;
            background-color: #F6F9FB
        }
        &-active{
            @extend .circled-coupon;
            border: 1px solid var(--color-general);
        }
    }
    &.border-active{
        border: 1px solid var(--color-general);
    }
    .space-img{
        border-right: dashed 3px #F6F6F6;
    }
    .text-cupon{
        line-height: 29px;
    }
    .text-detail{
        color: #b1b9c1;
    }
    .text-fetch{
        color: #D3D7DB;
    }
}
</style>
